export const colorTokens_latest = {
  Primary: {
    Charcoal: {
      "5": {
        type: "color",
        value: "#F4F4F6",
      },
      "10": {
        type: "color",
        value: "#ececef",
      },
      "30": {
        type: "color",
        value: "#d0d0d7",
      },
      "50": {
        type: "color",
        value: "#b4b4c0",
      },
      "80": {
        type: "color",
        value: "#646477",
      },
      "100": {
        type: "color",
        value: "#1f1f25",
      },
      alpha20: {
        type: "color",
        value: "#1f1f2520",
      },
      alpha80: {
        type: "color",
        value: "#1f1f2580",
      },
      alpha90: {
        type: "color",
        value: "#1f1f2590",
      },
    },
    Lavender: {
      "30": {
        type: "color",
        value: "#ebeafe",
      },
      "50": {
        type: "color",
        value: "#dddcfd",
      },
      "70": {
        type: "color",
        value: "#cfcefc",
      },
      "100": {
        type: "color",
        value: "#bbb9fb",
      },
      "130": {
        type: "color",
        value: "#7e7ee7",
      },
      "150": {
        type: "color",
        value: "#5d5dd5",
      },
      "190": {
        type: "color",
        value: "#333394",
      },
    },
    Magenta: {
      "10": {
        type: "color",
        value: "#fef1f1",
      },
      "20": {
        type: "color",
        value: "#fbc4de",
      },
      "30": {
        type: "color",
        value: "#f99dc8",
      },
      "70": {
        type: "color",
        value: "#f776b3",
      },
      "100": {
        type: "color",
        value: "#f33b92",
      },
      "110": {
        type: "color",
        value: "#e10f70",
      },
      "130": {
        type: "color",
        value: "#c50c63",
      },
    },
    Pure_white: {
      "100": {
        type: "color",
        value: "#ffffff",
      },
      alpha90: {
        type: "color",
        value: "#ffffffe6", // 90% opacity
      },
      alpha80: {
        type: "color",
        value: "#ffffffcc", // 80% opacity
      },
      alpha50: {
        type: "color",
        value: "#ffffff80", // 50% opacity
      },
      alpha20: {
        type: "color",
        value: "#ffffff20",
      },
    },
    Sand_grey: {
      "50": {
        type: "color",
        value: "#FBFAF9",
      },
      "70": {
        type: "color",
        value: "#F7F6F3",
      },
      "100": {
        type: "color",
        value: "#f5f4ee",
      },
      "110": {
        type: "color",
        value: "#e5e4dc",
      },
      "130": {
        type: "color",
        value: "#d1d0c7",
      },
      "150": {
        type: "color",
        value: "#c0bfb9",
      },
      "170": {
        type: "color",
        value: "#a6a5a0",
      },
      "190": {
        type: "color",
        value: "#71716f",
      },
      "200": {
        type: "color",
        value: "#3d3d3d",
      },
    },
    Yellow: {
      "10": {
        type: "color",
        value: "#fffced",
      },
      "30": {
        type: "color",
        value: "#fff7ca",
      },
      "50": {
        type: "color",
        value: "#fef2a6",
      },
      "70": {
        type: "color",
        value: "#feed82",
      },
      "100": {
        type: "color",
        value: "#fee64d",
      },
      "110": {
        type: "color",
        value: "#fedd0b",
      },
      "130": {
        type: "color",
        value: "#e5c601",
      },
    },
  },
  Secondary: {
    Marble_pink: {
      "10": {
        type: "color",
        value: "#fef8ff",
      },
      "30": {
        type: "color",
        value: "#fdebff",
      },
      "50": {
        type: "color",
        value: "#fbddff",
      },
      "70": {
        type: "color",
        value: "#f9d0ff",
      },
      "100": {
        type: "color",
        value: "#f7bcff",
      },
      "110": {
        type: "color",
        value: "#f699ff",
      },
      "130": {
        type: "color",
        value: "#f859f8",
      },
      "150": {
        type: "color",
        value: "#ac0eb4",
      },
      "170": {
        type: "color",
        value: "#6c007a",
      },
      "190": {
        type: "color",
        value: "#3f0047",
      },
    },
    Powder_blue: {
      "10": {
        type: "color",
        value: "#f1f4fe",
      },
      "30": {
        type: "color",
        value: "#d5dffd",
      },
      "50": {
        type: "color",
        value: "#b9c9fc",
      },
      "70": {
        type: "color",
        value: "#a1b6fb",
      },
      "100": {
        type: "color",
        value: "#7392f9",
      },
      "110": {
        type: "color",
        value: "#597ef8",
      },
      "130": {
        type: "color",
        value: "#2653e8",
      },
      "150": {
        type: "color",
        value: "#0a38d2",
      },
      "170": {
        type: "color",
        value: "#072ba2",
      },
      "190": {
        type: "color",
        value: "#051e71",
      },
    },
    Spring_green: {
      "10": {
        type: "color",
        value: "#f5fdf7",
      },
      "30": {
        type: "color",
        value: "#e2fae6",
      },
      "50": {
        type: "color",
        value: "#cff7d5",
      },
      "70": {
        type: "color",
        value: "#bcf4c4",
      },
      "100": {
        description: "Signup+",
        type: "color",
        value: "#9fefab",
      },
      "110": {
        type: "color",
        value: "#62e480",
      },
      "130": {
        type: "color",
        value: "#21c053",
      },
      "150": {
        type: "color",
        value: "#167e48",
      },
      "170": {
        type: "color",
        value: "#0c4631",
      },
      "190": {
        type: "color",
        value: "#072c1e",
      },
    },
    Water_azure: {
      "10": {
        type: "color",
        value: "#f1fcff",
      },
      "30": {
        type: "color",
        value: "#d5f5ff",
      },
      "50": {
        type: "color",
        value: "#b9eeff",
      },
      "70": {
        type: "color",
        value: "#9de8ff",
      },
      "100": {
        description: "Payments",
        type: "color",
        value: "#73deff",
      },
      "110": {
        type: "color",
        value: "#1fc3ff",
      },
      "130": {
        type: "color",
        value: "#009de0",
      },
      "150": {
        type: "color",
        value: "#0074ad",
      },
      "170": {
        type: "color",
        value: "#004a75",
      },
      "190": {
        type: "color",
        value: "#032c45",
      },
    },
  },
  Semantic: {
    Green: {
      "10": {
        type: "color",
        value: "#E5F9F3",
      },
      "30": {
        type: "color",
        value: "#b2ecdc",
      },
      "50": {
        type: "color",
        value: "#80dfc4",
      },
      "70": {
        type: "color",
        value: "#4cd2ac",
      },
      "100": {
        description: "Positive",
        type: "color",
        value: "#00bf89",
      },
      "110": {
        type: "color",
        value: "#00a375",
      },
      "130": {
        type: "color",
        value: "#008f66",
      },
      "170": {
        type: "color",
        value: "#036249",
      },
    },
    Red: {
      "10": {
        type: "color",
        value: "#ffebee",
      },
      "30": {
        type: "color",
        value: "#ffc3cc",
      },
      "50": {
        type: "color",
        value: "#ff9baa",
      },
      "70": {
        type: "color",
        value: "#ff7488",
      },
      "100": {
        description: "Negative",
        type: "color",
        value: "#ff3855",
      },
      "110": {
        type: "color",
        value: "#cc001f",
      },
      "130": {
        type: "color",
        value: "#b8001c",
      },
    },
  },
  ConsumerBrand: {
    Payment_lavender: {
      "30": {
        type: "color",
        value: "#E7E6FF",
      },
      "100": {
        type: "color",
        value: "#AFADFF",
      },
      "150": {
        type: "color",
        value: "#4D3BD8",
      },
    },
    Solid_black: {
      "5": {
        type: "color",
        value: "#F4F4F6",
      },
      "10": {
        type: "color",
        value: "#ECECEF",
      },
      "50": {
        type: "color",
        value: "#B4B4C0",
      },
      "80": {
        type: "color",
        value: "#646477",
      },
      "95": {
        type: "color",
        value: "#2B2B33",
      },
      "100": {
        type: "color",
        value: "#060606",
      },
    },
  },
} as const;
